@font-face {
    font-family: 'Raleway';
    src: local('Raleway'), url(./../../fonts/Raleway-Regular.ttf) format('truetype');
    
  }
@font-face {
    font-family: 'Playfair Display';
    src: local('Playfair Display'), url(./../../fonts/PlayfairDisplay-Regular.ttf) format('truetype');
    
  }
  

html *, html *::before, html *::after {
    box-sizing: inherit;
}
body {
    display: block;
    margin: 0px;
}
.home {
    font-family: "Raleway", "Montserrat", "Open Sans", Helvetica, sans-serif;
    scroll-behavior: smooth;
    font-weight: 300;
    font-size: 16px;
}
  body {
    font-family: "Roboto", sans-serif;
  }
  #root {
    background: #fff;
  }
#neighborhood_heading {
    padding-top: 30px;
    text-align: center;
}
.cy-custom {
    position: relative;
}
.cy-custom h4 {
    font-family: "Raleway", "Montserrat", "Open Sans", Helvetica, sans-serif;
    /* text-transform: uppercase; */
    letter-spacing: 10px;
    font-size: 20px;
    font-weight: 300;
    margin: 0;
}
.cy-custom h2 {
    font-family: "Playfair Display", serif;
    font-size: 60px;
    font-weight: 300;
    margin-top: 10px;
}
#neighborhoods {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 25px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 60px;
}
 #neighborhoods a {
    display: block;
    width: calc(100% / 3);
    position: relative;
    background: #000;
    overflow: hidden;
    border: 1px solid #fff;
    position: relative;
}
#neighborhoods a .inner {
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 50%;
    width: 90%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
    text-align: center;
    color: #fff;
}
#neighborhoods a .inner .title {
    font-weight: 600;
    font-size: 28px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: "Raleway", "Montserrat", "Open Sans", Helvetica, sans-serif;
}
#neighborhoods a .inner .subtitle {
    font-weight: 600;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-family: "Raleway", "Montserrat", "Open Sans", Helvetica, sans-serif;
}
#neighborhoods a img {
    width: 100%;
    opacity: 0.7;
    display: block;
    -webkit-transition: 300ms ease all;
    transition: 300ms ease all;
}
@media (max-width: 600px){
    
    #neighborhoods a {
        width: 50%;
    }
    .cy-custom h4 {
        font-size: 3.5vw;
    }
    .cy-custom h2 {
        font-size: 10vw;
    }
}
@media (max-width: 991px){
    #neighborhoods a {
        width: 100%;
    }
   
    .cy-custom h2 {
        font-size: 40px;
    }
}
#neighborhoods a:before {
    content: "";
    pointer-events: none;
    position: absolute;
    top: 15px;
    right: 15px;
    bottom: 15px;
    left: 15px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 10;
}
#neighborhoods a:after {
    content: "";
    background: url(https://cy-boomtown-assets.s3.us-west-1.amazonaws.com/sites/LuxuryLivingPDX.com/images/Luxe+Forbes+White.png) center no-repeat;
    background-size: contain;
    width: 100px;
    height: 26px;
    position: absolute;
    pointer-events: none;
    z-index: 10;
    right: 30px;
    bottom: 30px;
}

.footer-home {
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: #222222;
    color: white;
    text-align: center;
    padding:10px;
    font-family: "Raleway", "Montserrat", "Open Sans", Helvetica, sans-serif;
    letter-spacing: 8px;
    font-size: 10px;
    position:fixed;
    z-index: 998;
  }


.fade-in {
    animation: fade-in 2s ease forwards;
    transition: .5s;
}

.logoHome:hover {
    transform: scale(1.2);
  }

@keyframes fadeUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
@keyframes slideInFromRight {
    0% {
        display: block;
        opacity: 0;
        transform: translateX(100%);
    }
    50% {
        transform: translateX(0%);
    }
    100% {
        opacity: 1;
    }
}
@keyframes slideInFromLeft {
    0% {
        display: block;
        opacity: 0;
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(0%);
    }
    100% {
        opacity: 1;
    }
}
/* Fade-in from the right */
.fade-right {
    opacity: 0;
    transform: translateX(-20px);
    animation: fadeRight 2s ease forwards;
}
@keyframes fadeRight {
    from {
        opacity: 0;
        transform: translateX(-20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
/* Fade-in from the bottom */
.fade-down {
    opacity: 0;
    transform: translateY(-20px);
    animation: fadeDown 2s ease forwards;
}
@keyframes fadeDown {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
/* Fade-in from the left */
.fade-left {
    opacity: 0;
    transform: translateX(20px);
    animation: fadeLeft 2s ease forwards;
}
@keyframes fadeLeft {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}
@keyframes fade-in {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
